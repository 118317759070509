<template>
  <div class="merchantpreview">
    <NavBar name="商铺出租概览"></NavBar>
    <div class="merchantpreviewHead public-box">
      <div class="headLeft">
        <div class="title">
          <div class="title">
            <span class="name">当月商铺出租情况</span>
            <div class="totalName">
              总商铺数：<span>{{ (statisticsData && statisticsData.merchants_total) || 0 }}</span>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="progress">
            <el-progress :text-inside="true" :show-text="false" color="#528CFF" :stroke-width="26"
              :percentage="percentage"></el-progress>
          </div>
          <div class="progressContent">
            <div class="list">
              <div class="rentSeveralTitle">
                <span>已出租数</span>
              </div>
              <div class="sum">
                {{ (statisticsData && statisticsData.merchants_sell_total) || 0 }}
              </div>
            </div>
            <div class="list">
              <div class="noSeveralTitle">
                <span>未出租数</span>
              </div>
              <div class="sum">
                {{ (statisticsData && statisticsData.merchants_not_total) || 0 }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="list">
          <div class="sum newRent">
            <span>{{ (statisticsData && statisticsData.merchants_new_total) || 0 }}</span>
          </div>
          <div class="name">新租出商铺数</div>
        </div>
        <div class="list">
          <div class="sum comparisonRent">
            <span>{{ (statisticsData && statisticsData.merchants_contrast_total) || 0 }}</span>
          </div>
          <div class="name">对比上月多出租商铺数</div>
        </div>
        <div class="list">
          <div class="sum clearRent">
            <span>{{ (statisticsData && statisticsData.merchants_cancel_total) || 0 }}</span>
          </div>
          <div class="name">取消租赁商铺数</div>
        </div>
      </div>
    </div>
    <el-form :inline="true">
      <el-form-item>
        <el-date-picker v-model="date" @change="(e) => { $common.dateChange(e, searchForm, 'beginTime', 'endTime') }"
          value-format="yyyy-MM" type="monthrange" placeholder=""></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-select v-model="searchForm.property_type_id" @change="getList" placeholder="商铺类型">
          <el-option label="全部" :value="0"></el-option>
          <el-option v-for="(item, index) in shoplist" :label="item.name" :key="index"
            :value="item.property_type_id"></el-option>
        </el-select>
        <!-- <MyButton icon title="折线图" @click="open">
          <template slot="preImage">
            <img src="../../../unit/img/zhexiantu.png">
          </template>
        </MyButton> -->
      </el-form-item>
      <el-form-item>
        <MyButton title="搜索" @click="getList">
          <template slot="preImage">
            <img src="../../../unit/img/search.png" />
          </template>
        </MyButton>
      </el-form-item>
      <el-form-item>
        <MyButton title="导出" :accessId="35398" @click="leaseLogExport">
          <template slot="preImage">
            <img src="../../../unit/img/down.png" />
          </template>
        </MyButton>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="tableData" :header-cell-style="{
      'text-align': 'center',
      background: 'rgb(245, 245, 245)',
    }" :cell-style="{ 'text-align': 'center' }">
      <el-table-column label="时间(年-月)" prop="month"></el-table-column>
      <el-table-column label="商铺类型" prop="property_type_name"></el-table-column>
      <el-table-column label="总商铺数(个)/总面积(㎡)" prop="merchants_total">
        <template #default="{ row }">
          <span>{{ row.merchants_total }} / {{ row.merchants_size }}</span>
        </template>
      </el-table-column>
      <el-table-column label="已出租商铺数(个)/总面积(㎡)" prop="merchants_sell_total">
        <template #default="{ row }">
          <span>{{ row.merchants_sell_total }} / {{ row.merchants_sell_size }}</span>
        </template>
      </el-table-column>
      <el-table-column label="未出租商铺数(个)/总面积(㎡)" prop="merchants_not_total">
        <template #default="{ row }">
          <span>{{ row.merchants_not_total }} / {{ row.merchants_not_size }}</span>
        </template>
      </el-table-column>
      <el-table-column label="新租出商铺数(个)/总面积(㎡)" prop="merchants_new_total">
        <template #default="{ row }">
          <span>{{ row.merchants_new_total }} / {{ row.merchants_new_size }}</span>
        </template>
      </el-table-column>
      <el-table-column label="对比上月多出租商铺数(个)/总面积(㎡)" prop="merchants_contrast_total">
        <template #default="{ row }">
          <span>{{ row.merchants_contrast_total }} / {{ row.merchants_contrast_size }}</span>
        </template>
      </el-table-column>
      <el-table-column label="取消租赁商铺数(个)/总面积(㎡)" prop="merchants_cancel_total" width="220px">
        <template #default="{ row }">
          <span>{{ row.merchants_cancel_total }} / {{ row.merchants_cancel_size }}</span>
        </template>
      </el-table-column>
      <el-table-column label="出租率" prop="month">
        <template #default="{ row }">
          <span>{{ rentrate(row) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="收费情况表" :visible.sync="dialogVisible" width="80%" :close-on-click-modal="false">
      <el-select v-model="type" placeholder="选择类型" clearable class="offset margin-B15">
        <el-option v-for="item in merchantStatus" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <div id="echartsMerchantpreview" style="width: 100%; height: 400px"></div>
    </el-dialog>
  </div>
</template>
<script>
import * as echarts from "echarts";

export default {
  name: "merchantpreview",
  data () {
    return {
      tableData: [],
      dialogVisible: false,
      year: "",
      statisticsData: null,
      percentage: 0,
      dateList: [],
      type: "",
      merchantStatus: [
        {
          label: "已出租商铺数",
          value: "1",
          data: [],
          isShow: true,
        },
        {
          label: "已出租商铺数",
          value: "2",
          data: [],
          isShow: true,
        },
        {
          label: "未出租商铺数",
          value: "3",
          data: [],
          isShow: true,
        },
        {
          label: "对比上月多出租商铺数",
          value: "4",
          data: [],
          isShow: true,
        },
        {
          label: "取消租赁商铺数",
          value: "5",
          data: [],
          isShow: true,
        },
      ],
      echartsMerchantpreview: "",
      shoplist: [],
      date: [],
      searchForm: {
        beginTime: "",
        endTime: "",
        property_type_id: 0,
      },
      loading: false,
    };
  },
  created () {
    this.year = new Date().getFullYear().toString();
    this.getList();
    this.getshoptype();
  },
  methods: {
    leaseLogExport () {
      this.$request
        .HttpPost("/lease_log/export", this.formInline, {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res]);
          let objectUrl = URL.createObjectURL(blob);
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = objectUrl;
          link.setAttribute("download", "商铺出租列表.xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); // 下载完成移除元素
          window.URL.revokeObjectURL(objectUrl); // 释放掉blob对象
        });
    },
    calculateData () {
      let series = [];
      for (let i in this.merchantStatus) {
        if (this.merchantStatus[i].isShow) {
          series.push({
            name: this.merchantStatus[i].name,
            type: "line",
            data: this.merchantStatus[i].data,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                },
                color: "#3D7EFF",
              },
            },
          });
        }
      }
      return series;
    },
    open () {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.echartsMerchantpreview = echarts.init(
          document.getElementById("echartsMerchantpreview")
        );
        let arr = this.calculateData();
        let option = {
          tooltip: {
            trigger: "axis",
          },
          xAxis: {
            data: this.dateList,
          },
          yAxis: {},
          series: arr,
        };
        this.echartsMerchantpreview.setOption(option);
      });
    },
    changeYear (year) {
      this.year = year;
      this.dateList = [];
      this.getList();
    },
    getList () {
      this.loading = true;
      this.$request
        .HttpPost("/lease_log/list", this.searchForm)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.list.slice(0, -1);
          this.statisticsData = res.data.list[res.data.list.length - 1];
          this.percentage =
            (this.statisticsData.merchants_sell_total /
              this.statisticsData.merchants_total) *
            100;
          for (let i in this.tableData) {
            this.merchantStatus[0].data.push(
              this.tableData[i].merchants_sell_total
            );
            this.merchantStatus[1].data.push(
              this.tableData[i].merchants_not_total
            );
            this.merchantStatus[2].data.push(
              this.tableData[i].merchants_new_total
            );
            this.merchantStatus[3].data.push(
              this.tableData[i].merchants_contrast_total
            );
            this.merchantStatus[4].data.push(
              this.tableData[i].merchants_cancel_total
            );
            this.dateList.push(this.tableData[i].month);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getshoptype () {
      this.$request.HttpGet("/property/type/list").then((res) => {
        if (res.data) {
          this.shoplist = res.data.list;
        }
      });
    },
    rentrate (item) {
      return ((item.merchants_sell_total / item.merchants_total * 100).toFixed(2) + "%");
    },
  },
};
</script>
<style lang="scss" scoped>
.merchantpreview {
  .merchantpreviewHead {
    display: flex;
    height: 208px;
    margin-bottom: 40px;

    .headLeft {
      flex: 1.1;
      border: 1px solid #dedede;
      margin-right: 10px;
      box-sizing: border-box;
      padding: 20px;

      .title {
        display: flex;
        align-items: flex-end;

        .name {
          color: #000000;
          font-size: 14px;
          margin-right: 20px;
        }

        .totalName {
          font-size: 12px;
          color: #999999;

          span {
            color: #333333;
          }
        }
      }

      .content {
        padding: 0 60px;
        margin-top: 60px;
        display: flex;
        align-items: center;

        .progress {
          flex: 1;
          margin-right: 100px;

          /deep/ .el-progress-bar__outer {
            background-color: #3dddff;
          }
        }

        .progressContent {
          display: flex;

          .list {
            .rentSeveralTitle {
              font-size: 12px;
              color: #333333;
              margin-right: 70px;

              span {
                position: relative;

                &:before {
                  content: " ";
                  width: 8px;
                  height: 8px;
                  background: #528cff;
                  position: absolute;
                  left: -20px;
                  top: 4px;
                }
              }
            }

            .noSeveralTitle {
              font-size: 12px;
              color: #333333;

              span {
                position: relative;

                &:before {
                  content: " ";
                  width: 8px;
                  height: 8px;
                  background: #3dddff;
                  position: absolute;
                  left: -20px;
                  top: 4px;
                }
              }
            }

            .sum {
              font-size: 18px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #333333;
              margin-top: 10px;
            }
          }
        }
      }
    }

    .right {
      flex: 0.9;
      border: 1px solid #dedede;
      box-sizing: border-box;
      padding: 0 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .list {
        .sum {
          font-size: 18px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          text-align: center;
          display: flex;
          align-items: center;

          span {
            display: block;
            width: 100%;
          }
        }

        .newRent {
          width: 80px;
          height: 80px;
          background: url("../../../assets/img/addchuzu.png") #ffffff;
          background-size: 80px 80px;
        }

        .comparisonRent {
          width: 80px;
          height: 80px;
          background: url("../../../assets/img/duibi.png") #ffffff;
          background-size: 80px 80px;
        }

        .clearRent {
          width: 80px;
          height: 80px;
          background: url("../../../assets/img/quxiao.png") #ffffff;
          background-size: 80px 80px;
        }

        .name {
          margin-top: 10px;
          text-align: center;
          font-size: 12px;
          color: #333333;
        }
      }
    }
  }
}

.diy {
  /deep/ .el-input__inner {
    background: none;
    border: none;
    text-align: center;
  }
}
</style>
